import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import moment from "moment"

import { toastSuccess, toastError } from "../utils/myToasts"
import { updateSubscriptionNickPromise } from "../utils/CallAPIs"
import { getSubscriptionFeature as getFeature } from "../store"

const CardContainer = styled.div`
  width: 93%;
  border: 1px solid #c4c7ce;
  border-radius: 8px;
  display: flex;
  flex-flow: row;
  padding: 20px;
  margin-bottom: 20px;

  .ccc1b-input {
    color: #419bf9;
    width: 120px;
    margin-right: 16px;

    line-height: 24px;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
    border: 1px solid transparent;
    font-size: 16px;
  }

  .ccc1b-input:focus {
    color: red;
  }
`

const MyRenewsIn = styled.div`
  display: flex;
  flex-flow: column;
  margin: auto 0;
  padding-right: 10px;
  flex-grow: 1;

  p {
    margin: 0;
  }
  .p-renews-in {
    color: #9ba1aa;
    font-size: 12px;
  }

  .p-renews-in-no {
    font-size: 14px;
  }
`
const RenewsIn = ({ renewInDays, expiresInDays }) => {
  return (
    <MyRenewsIn>
      <p className="p-renews-in">{renewInDays ? "Renews" : "Expires"} in:</p>
      <p className="p-renews-in-no">{renewInDays || expiresInDays}</p>
    </MyRenewsIn>
  )
}

const MyProxyType = styled.div`
  display: flex;
  flex-flow: column;
  margin: auto 0;
  padding-right: 10px;
  flex-grow: 1;

  .p-type {
    margin: 0;
    color: #9ba1aa;
    font-size: 12px;
  }

  span {
    font-size: 14px;
  }
  .first-span {
    color: #419bf9;
  }

  .second-span {
  }
`
const ProxyType = ({ proxyType, proxyTypeServicesOffered }) => {
  let _type = proxyTypeServicesOffered && proxyTypeServicesOffered.split(",")[0]
  // Just to make sure 1st index of services-offered is correct type
  if (!_type && (_type.includes("4G") || _type.includes("5G"))) {
    _type = null
  }
  return (
    <MyProxyType>
      <p className="p-type">Type:</p>
      <div>
        <span className="first-span">{proxyType}</span>{" "}
        {!!_type && <span className="second-span">({_type})</span>}
      </div>
    </MyProxyType>
  )
}
const MyProxyDetail = styled.div`
  display: flex;
  flex-flow: column;
  margin: auto 0;
  padding-right: 10px;
  flex-grow: 1;

  p {
    margin: 0;
  }
  .p-key {
    color: #9ba1aa;
    font-size: 12px;
  }
  .p-value {
    color: #6abf83;
    font-size: 14px;
  }
  .single-row {
    color: black;
    margin: 2px 0 2px 0;
    font-size: 14px;
  }
`

// const ProxyDetail = props => {
//   const { theKey, theValue } = props
//   return (
//     <MyProxyDetail>
//       <p className="p-key">{theKey}</p>
//       <p className="p-value">{theValue}</p>
//     </MyProxyDetail>
//   )
// }
const ProxyDetail = props => {
  const { modem } = props
  return (
    <MyProxyDetail>
      {modem && (
        <>
          <div className="single-row">
            <span>HTTP Port: </span>
            <span>{modem.httpPort}</span>
          </div>
          <div className="single-row">
            <span>Proxy IP: </span>
            <span>{modem.proxyIp}</span>
          </div>
          <div className="single-row">
            <span>Username: </span>
            <span>{modem.proxyUsername}</span>
          </div>
          <div className="single-row">
            <span>Password: </span>
            <span>{modem.proxyPassword}</span>
          </div>
        </>
      )}
    </MyProxyDetail>
  )
}

const MyManageButton = styled.button`
  background-color: #8a59ef;
  display: table;
  margin: auto 10px;
  padding-right: 10px;
  color: white;
  height: 30px;
  width: 90px;
  border-radius: 8px;
  border: 0;
  font-weight: 700;
  text-align: center;
`

export default function ProxyListCard(props) {
  const { id, updateNickOfThisSubscription, modem = null } = props

  const renewInDays = moment(props.autoRenewAt).diff(new Date(), "days")
  const expiresInDays = moment(props.expiredAt).diff(new Date(), "days")
  const showDays = renewInDays > 0 || expiresInDays > 0
  const proxy = getFeature(props, "Proxy Type")
  // const ipRotation = getFeature(props, "IP Rotation")
  // const dataPrioritization = getFeature(props, "Data Prioritization")
  // const domainWhitelisting = getFeature(props, "Domain Whitelisting")
  const [nick, setNick] = useState(props.nickName || proxy.title)

  return (
    <CardContainer>
      <input
        className="ccc1b-input"
        value={nick}
        onChange={e => setNick(e.target.value)}
        maxLength="12"
        onBlur={async () => {
          try {
            if (nick === proxy.title) {
              return
            }
            let res = await updateSubscriptionNickPromise(id, nick)
            if (res.status === 200) {
              toastSuccess(
                "proxy name changed to " + res.data.data.attributes["nick-name"]
              )
              updateNickOfThisSubscription(
                res.data.data.attributes["nick-name"],
                id
              )
              setNick(res.data.data.attributes["nick-name"])
            }
          } catch (err) {
            console.error(err)
            toastError("failed to change proxy name...")
          }
        }}
      ></input>
      {showDays && (
        <RenewsIn renewInDays={renewInDays} expiresInDays={expiresInDays} />
      )}
      <ProxyType
        proxyType={proxy.title}
        proxyTypeServicesOffered={proxy.servicesOffered}
      ></ProxyType>
      <ProxyDetail modem={modem}></ProxyDetail>
      <Link to={"/proxies/manage?id=" + id} style={{ textDecoration: "none" }}>
        <MyManageButton children="Manage" />
      </Link>
    </CardContainer>
  )
}
