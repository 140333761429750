import React from "react"
import styled from "styled-components"

import ProxyBigCard from "./ProxyBigCard"
import ProxyListCard from "./ProxyListCard"
import ProxyAddCard from "./ProxyAddCard"

const MyProxiesApp = styled.div`
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 960px) {
    margin: 0 30px;
    flex-wrap: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 961px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
`

const proxyLogoLinks = [
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188729/rock%20proxies%20images/rock_proxies_images_Group_166_hlvewa.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188729/rock%20proxies%20images/rock_proxies_images_Group_165_hbdnb4.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_161_bpfal1.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_163_mj4ttt.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_164_bqrjxr.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_162_jdpbuk.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_160_pzi23s.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_157_tkkv60.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_158_tjebwt.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_159_koyrpp.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_156_qgqdmf.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_155_wzapor.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_153_vea0nb.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_154_tcyu0s.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_147_eizmah.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_152_n6a7go.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_146_eewfms.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_145_wpt16k.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_150_or0auz.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_151_pnw0u1.png",
]

export default function ProxiesApp({
  isMobile,
  proxiesPageCardSize,
  allSubscriptions,
  updateNickOfThisSubscription,
}) {
  return (
    <MyProxiesApp>
      {allSubscriptions.length === 0 && (
        <ProxyAddCard logoLink={proxyLogoLinks[0]} />
      )}

      {allSubscriptions.map((elem, idx) => {
        // @TODO: Replace these two with a single responsive component
        if (isMobile === true || proxiesPageCardSize === "large") {
          return (
            <ProxyBigCard
              key={elem.id}
              logoLink={proxyLogoLinks[idx % proxyLogoLinks.length]}
              updateNickOfThisSubscription={updateNickOfThisSubscription}
              {...elem}
            />
          )
        }

        return (
          <ProxyListCard
            key={elem.id}
            updateNickOfThisSubscription={updateNickOfThisSubscription}
            {...elem}
          ></ProxyListCard>
        )
      })}
    </MyProxiesApp>
  )
}
